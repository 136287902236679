<template>
  <div class="gallery-container">
    <v-carousel
      :cycle="true"
      :interval="5000"
      hide-delimiters
      class="gallery-carousel"
      :show-arrows="false"
      height="100%"
      :id="galleryId"
    >
      <v-carousel-item
        v-for="(image, index) in images"
        :key="index"
        @click="openGallery(index)"
        cover
        :src="image.path"
        :class="{ 'blur-explicit': image.rejected }"
      >
      </v-carousel-item>
    </v-carousel>

    <v-dialog
      v-if="images.length === 1"
      v-model="dialogOpen"
      max-width="500px"
      transition="dialog-transition"
      content-class="elevation-0"
    >
      <img
        :src="images[0].path"
        class="transition-ease cursor-pointer"
        @click="closeGallery"
        :alt="'Image'"
      />
    </v-dialog>

    <v-dialog
      v-if="images.length > 1"
      v-model="dialogOpen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      content-class="fullscreen-dialog"
    >
      <div class="gallery-fullscreen">
        <v-btn
          icon
          @click="closeGallery"
          class="close-btn"
          color="white"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        
        <v-btn
          icon
          @click="previousImage"
          class="nav-btn left-btn"
          color="white"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="nextImage"
          class="nav-btn right-btn"
          color="white"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <div class="image-container">
          <transition :name="transitionName">
            <div
              :key="currentIndex"
              class="image-wrapper"
            >
              <img
                :src="currentImage"
                class="fullscreen-image"
                @click="closeGallery"
                :alt="'Image ' + (currentIndex + 1)"
                @touchstart="touchStart"
                @touchmove="touchMove"
                @touchend="touchEnd"
              />
            </div>
          </transition>
        </div>
        <div class="image-counter">
          {{ currentIndex + 1 }} / {{ images.length }}
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'GalleryComponentCard',
  props: {
    images: {
      type: Array,
      required: true
    },
    galleryId: {
      type: String,
      required: true
    },
    editor: {
      type: Boolean,
      default: false
    },
    explicit: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      dialogOpen: false,
      currentIndex: 0,
      touchStartX: 0,
      touchEndX: 0,
      transitionName: 'slide-left'
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex].path;
    }
  },

  methods: {
    openGallery(index) {
      if (this.editor) {
        return;
      } else if (this.explicit) {
        this.$swal({
          title: `<strong style="font-size: 26px;">${this.$t('harmony.contentExplicit')}</strong>`,
          html: `<p>${this.$t('harmony.contentExplicitDesc')}</p>`,
          iconHtml: '<i class=" mdi mdi-eye-off-outline"> </i>',
          customClass: {
            icon: 'notborder',
            title: 'titleSwal',
          },
          showCancelButton: true,
          confirmButtonText: `<i class="fa fa-thumbs-up"></i> ${this.$t('harmony.showContentExplicit')}`,
          cancelButtonText: `${this.$t('harmony.hideContentExplicit')}`,
          confirmButtonColor: '#8e84c0',
          cancelButtonColor: '#d33',
          background: '#f2f2f2',
          backdrop: `
          rgba(0, 0, 0, 0.7) no-repeat
        `,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.currentIndex = index;
            this.dialogOpen = true;
          }
        });
      } else{
        this.currentIndex = index;
        this.dialogOpen = true;
      }
    },
    closeGallery() {
      this.dialogOpen = false;
    },
    nextImage() {
      this.transitionName = 'slide-left';
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    previousImage() {
      this.transitionName = 'slide-right';
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    touchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    touchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    touchEnd() {
      const swipeThreshold = 50;
      const swipeDistance = this.touchEndX - this.touchStartX;

      if (Math.abs(swipeDistance) > swipeThreshold) {
        if (swipeDistance > 0) {
          this.previousImage();
        } else {
          this.nextImage();
        }
      }
      this.touchStartX = 0;
      this.touchEndX = 0;
    }
  }
};
</script>

<style scoped>

::v-deep .fullscreen-dialog {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100vw !important;
  max-height: 100vh !important;
}

.blur-explicit {
 filter: blur(2px);
}

.gallery-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.gallery-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery-fullscreen {
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

.fullscreen-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  user-select: none;
  -webkit-user-drag: none;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000 !important;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.left-btn {
  left: 20px;
}

.right-btn {
  right: 20px;
}

.image-counter {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 16px;
  z-index: 2;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform;
}

.slide-left-enter {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

.transition-ease {
    transition: all 0.3s ease-in-out;
    border-radius: 2rem !important;
}
</style>
<style>
.notborder {
  border: none;
}

.titleSwal {
  padding-top: 0px !important;
}
</style>